import { Card } from "react-bootstrap";
import Button from "react-bootstrap/Button";

export default function TCCCRender() {

  var _paq = window._paq = window._paq || [];
  /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
  _paq.push(['trackPageView']);
  _paq.push(['enableLinkTracking']);
  (function() {
    var u="https://matomo.aws.coh.arizona.edu/";
    _paq.push(['setTrackerUrl', u+'matomo.php']);
    _paq.push(['setSiteId', '64']);
    var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
    g.async=true; g.src=u+'matomo.js'; s.parentNode.insertBefore(g,s);
  })();

  return (
    <>
      <div className="poem">
        <div className="cardContainer card">
          <div className="card-body">
            <div className="card-title h5" style={{ fontSize: "35px" }}>
            Li Duan (738?-786?): Listening to the Zither
            </div>
            <p>
              1 Its strings on their golden bridges, the zither in tune arises;
              <br />
              2 At her fair hands’ fingertips, its resonance chamber in chime.
              <br />
              3 Desiring the caring attention of the man dear to her heart,
              <br />
              4 Coyly, she plucks and plays a wrong note from time to time.
              <br />
              <br />
              Translated from the original – 李端: 聽箏
              <br />
              1 鳴箏金粟柱
              <br />
              2 素手玉房前
              <br />
              3 欲得周郎顧
              <br />
              4 時時誤拂絃
              <br />
              <br />
              <div className="mb-2 text-muted card-subtitle h6">
                Translation by Andrew W.F. Wong (Huang Hongfa) 黃宏發 from {" "}
                <a href="https://chinesepoemsinenglish.blogspot.com/2020/03/li-duan-listening-to-zither.html">
                  Classical Chinese Poems in English
                </a>
                <br />
              </div>
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
