import Button from "react-bootstrap/Button";

export default function BorderlandsRender() {

  var _paq = window._paq = window._paq || [];
  /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
  _paq.push(['trackPageView']);
  _paq.push(['enableLinkTracking']);
  (function() {
    var u="https://matomo.aws.coh.arizona.edu/";
    _paq.push(['setTrackerUrl', u+'matomo.php']);
    _paq.push(['setSiteId', '64']);
    var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
    g.async=true; g.src=u+'matomo.js'; s.parentNode.insertBefore(g,s);
  })();

  return (
    <>
      <div className="buttonContainer">
        <Button
          className="ARbuttonWorks"
          href={"/borderlands-ar01en"}
          target="_blank">
          Introduction to Westside Stories AR (English)
        </Button>
        <Button
          className="ARbuttonWorks"
          href={"/borderlands-ar01es"}
          target="_blank">
          Introduction to Westside Stories AR (Español)
        </Button>
        <Button
          className="ARbuttonWorks"
          href={"/borderlands-ar02"}
          target="_blank">
          Experience "Garden of Gethsemane" AR
        </Button>
        <Button
          className="ARbuttonWorks"
          href={"/borderlands-ar03"}
          target="_blank">
          Experience "Mercados Chinos" AR
        </Button>
      </div>
      <div className="buttonContainer">
        <Button
          className="ARbuttonWorks"
          href={"/borderlands-ar04"}
          target="_blank">
          Experience "Ode to Sentinel Peak" AR
        </Button>
        <Button
          className="ARbuttonWorks"
          href={"/borderlands-ar05"}
          target="_blank">
          Experience "Casas De Adobe" AR
        </Button>
        <Button
          className="ARbuttonWorks"
          href={"/borderlands-ar06en"}
          target="_blank">
          Experience "Women of the Westside" AR (English)
        </Button>
        <Button
          className="ARbuttonWorks"
          href={"/borderlands-ar06es"}
          target="_blank">
          Experience "Women of the Westside" AR (Español)
        </Button>
      </div>
    </>
  );
}
