import { generatePath } from "react-router-dom";

export const paths = {
  HOME: "/",
  MAP: "/map",
  LIMYOU: "/LimyouSign",
  EMPIRE: "/EmpireSign",
  ALANS: "/AlansSign",
  SITE: "/:siteName",
  BORDERLANDS1: "borderlands-ar01en",
  BORDERLANDS2: "borderlands-ar01es",
  BORDERLANDS3: "borderlands-ar02",
  BORDERLANDS4: "borderlands-ar03",
  BORDERLANDS5: "borderlands-ar04",
  BORDERLANDS6: "borderlands-ar05",
  BORDERLANDS7: "borderlands-ar06en",
  BORDERLANDS8: "borderlands-ar06es",
  prar: "prar1",
  mg1: "mg-ar01",
  asm1: "asm-ar01",
  chd1: "chd-ar01",
  alans: "alans",
  empire: "empire",
  limyou: "limyou"
};

export function generateSitePath(siteName) {
  return generatePath(paths.SITE, {
    siteName: siteName.replace(/\s+/g, ""),
  });
}
