import React, { useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Homepage from "./screens/Homepage/Homepage";
import Navbar from "./components/Navbar/Navbar";
import Limyou from "./components/ImagePages/Limyou";
import Empire from "./components/ImagePages/Empire";
import Alans from "./components/ImagePages/Alans";
import Map from "./components/Map/Map";
import DetailsPage from "./screens/DetailsPage/DetailsPage";
import WritingActivities from "./components/WritingActivities/WritingActivities";
import limyouPic from "./images/graphics/LimYou sign final.jpeg";
import { paths } from "./util/paths";

function App() {
  const ExternalRedirect = ({ to }) => {
    useEffect(() => {
      window.location.href = to;
    }, [to]);

    return null;
  };
  return (
    <BrowserRouter>
      <Navbar />
      <Routes>
        <Route exact path={paths.HOME} element={<Homepage />} />
        <Route path={paths.MAP} element={<Map />} />
        <Route path={paths.LIMYOU} element={<Limyou />} />
        <Route path={paths.EMPIRE} element={<Empire />} />
        <Route path={paths.ALANS} element={<Alans />} />
        <Route path={paths.SITE} element={<DetailsPage />} />
        <Route path={"/WritingActivities"} element={<WritingActivities />} />
        <Route
          path={paths.BORDERLANDS1}
          element={
            <ExternalRedirect to="https://ar.blippar.com/rffgeudbwvvjc1iferofgpp1zeomxkzqtcp73srg" />
          }
        />
        <Route
          path={paths.BORDERLANDS2}
          element={
            <ExternalRedirect to="https://ar.blippar.com/qzatz2tlw2jo3nxgadl41uasctbhvzmlmg9ab5zn" />
          }
        />
        <Route
          path={paths.BORDERLANDS3}
          element={
            <ExternalRedirect to="https://ar.blippar.com/pyudh6vp6wohkqhgi8ngrdb8d4bn4pom4wmiphym" />
          }
        />
        <Route
          path={paths.BORDERLANDS4}
          element={
            <ExternalRedirect to="https://ar.blippar.com/kjyms1jksrgx6ucofxfefmzfsyf95tx08av7y2sl" />
          }
        />
        <Route
          path={paths.BORDERLANDS5}
          element={
            <ExternalRedirect to="https://ar.blippar.com/vdyfxbdk3bdx62cgbwh7byo3ndzx3w8txq61xb32" />
          }
        />
        <Route
          path={paths.BORDERLANDS6}
          element={
            <ExternalRedirect to="https://ar.blippar.com/4yuyp5rf87r4v23g3xc9pow08srtb2qqr34ah6m6" />
          }
        />
        <Route
          path={paths.BORDERLANDS7}
          element={
            <ExternalRedirect to="https://ar.beem.me/message/university_of_arizona/55d5f084-c735-11ed-94a0-0242ac120005" />
          }
        />
        <Route
          path={paths.BORDERLANDS8}
          element={
            <ExternalRedirect to="https://ar.beem.me/message/university_of_arizona/eccebdfe-c73a-11ed-b2c7-0242ac120005" />
          }
        />
        <Route
          path={paths.prar}
          element={
            <ExternalRedirect to="https://ar.blippar.com/15f2toxgeb0ipo8hxv3z1ykszazceu7sp2rc9jlm" />
          }
        />
        <Route
          path={paths.mg1}
          element={
            <ExternalRedirect to="https://ar.blippar.com/w22ac1qn2n1rhkr2jcxts6ep4e45widb17fqcdrv" />
          }
        />
        <Route
          path={paths.asm1}
          element={
            <ExternalRedirect to="https://ar.blippar.com/gn000mgcmztaeuhm53bdrrv3llvjcm6vjfnzlybn" />
          }
        />
        <Route
          path={paths.chd1}
          element={
            <ExternalRedirect to="https://ar.blippar.com/rrxxmnb72crs81argun8fvj71f6b92l8ayhuojwz" />
          }
        />
        <Route
          path={paths.alans}
          element={
            <ExternalRedirect to="https://ar.blippar.com/z5jynd0g8twof4l45j5yb59mz17h6x221z5wuplo" />
          }
        />
        <Route
          path={paths.empire}
          element={
            <ExternalRedirect to="https://ar.blippar.com/25g1qiznitgvktkppxncwzxlvt7so3fqk6j1oc4i" />
          }
        />
        <Route
          path={paths.limyou}
          element={
            <ExternalRedirect to="https://ar.blippar.com/d5j3yp9ftbpnkzskk6lmnxp8jkr2ohnlxatqfsep" />
          }
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
