import Gallery from "react-photo-gallery";
import "./ASMRender.css";
import Button from "react-bootstrap/Button";
import pottery1 from "../../../images/potteryPictures/Agave Fiber 02.jpg";
import pottery2 from "../../../images/potteryPictures/Chicago architecture biennial 01.jpg";
import pottery3 from "../../../images/potteryPictures/Cloud Weave.jpg";
import pottery4 from "../../../images/potteryPictures/Copper Coil 03 .jpg";
import pottery5 from "../../../images/potteryPictures/Desert Paper 05.jpg";
import pottery6 from "../../../images/potteryPictures/Desert Paper 07.jpg";
import pottery7 from "../../../images/potteryPictures/Desert Paper 09.jpg";
import pottery8 from "../../../images/potteryPictures/Desert Paper 11.jpg";
import pottery9 from "../../../images/potteryPictures/Desert Paper 18 .jpg";
import pottery10 from "../../../images/potteryPictures/Form Over Function.jpg";
import pottery11 from "../../../images/potteryPictures/Gourd basket.jpg";
import pottery12 from "../../../images/potteryPictures/Grass Coil 01.jpg";
import pottery13 from "../../../images/potteryPictures/Grass Coil 02.jpg";
import pottery14 from "../../../images/potteryPictures/Horse Hair Coil 02.jpg";
import pottery15 from "../../../images/potteryPictures/Horse Hair and Wood 02.jpg";
import pottery16 from "../../../images/potteryPictures/Inlaid Gourd Basket.jpg";
import pottery17 from "../../../images/potteryPictures/Knot 03 .jpg";
import pottery18 from "../../../images/potteryPictures/Meeting The Clouds Halfway 01.jpg";
import pottery19 from "../../../images/potteryPictures/Meeting The Clouds Halfway 02.jpg";
import pottery20 from "../../../images/potteryPictures/The Conversation.jpg";
import pottery21 from "../../../images/potteryPictures/Where the Clouds Live.jpg";
import pottery22 from "../../../images/potteryPictures/Wire Coil 06 with Yucca .jpg";
import pottery23 from "../../../images/potteryPictures/Wood Basket 02.jpg";
import React, { useState, useEffect } from "react";

export default function ASMRender() {

  var _paq = window._paq = window._paq || [];
  /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
  _paq.push(['trackPageView']);
  _paq.push(['enableLinkTracking']);
  (function() {
    var u="https://matomo.aws.coh.arizona.edu/";
    _paq.push(['setTrackerUrl', u+'matomo.php']);
    _paq.push(['setSiteId', '64']);
    var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
    g.async=true; g.src=u+'matomo.js'; s.parentNode.insertBefore(g,s);
  })();

  return (
    <>
      <div className="buttonContainer">
        <Button
          className="ARbuttonTmp"
          href="https://ar.blippar.com/gn000mgcmztaeuhm53bdrrv3llvjcm6vjfnzlybn">
          Experience AR
        </Button>
      </div>

      <div className="poem">
        <div className="cardContainer card">
          <div className="card-body">
            <div className="card-title h5">Huataddam / Fibers on the Land</div>
            <div className="mb-2 text-muted card-subtitle h6">
              By Ofelia Zepeda
              <br />
            </div>
            <p>
              ‘Am ñeid g takwi mo s-ke:g am cu:cc
              <br />
              We:peg ‘am e-ho’igel
              <br />
              K ‘am o tai
              <br />
              A:pi takwi
              <br />
              Map si s-ke:gaj
              <br />
              ‘I:da ‘att ‘ia o m-tai k o hekaj
              <br />
              Ab ‘att o ‘ui m-amjeḍ
              <br />
              K amjeḍ ‘am o i-m-dagĭto.
              <br />
              S-ke:gaj ‘o g m-ma:kig.
              <br />
              ‘A:pi moho s-ke:k ‘ia wu:ṣañ
              <br />
              We:sko ‘ap wuṣke ‘am ki:him t-am.
              <br />
              ‘Am ‘att o m-hikkumia k ‘an o m-bei.
              <br />
              Hemu ‘apt an ‘ep o wu:ṣ k ba’ic ‘i o s-ke:gajk.
              <br />
              ‘A:pi ‘ihug ñ-oidag ‘eḍ ‘apt wu:ṣ.
              <br />
              M-ñeid añ mapt ‘an ‘i-hio kc ‘an bai
              <br />
              s-ke:gaj ‘ap mapt ‘an i-wu:ṣ’.
              <br />
              ‘An o m-bei k o huat.
              <br />
              Ñia’a, ñeid g O’odham mo ‘an ‘e-keihin.
              <br />
              Ñeid g komckuḍ mo ‘ab dahă kc g ba:ban mo
              <br />
              ‘An hihim k ‘am ‘aṣkia s-maṣwog g ha-go:kĭ.
              <br />
              Ha:l hiosig mo s-ap ‘ab daha kc g hohogimal
              <br />
              Mo ‘an ñe:ñe’e.
              <br />
              Ñia’a, ñia’a s-ap ‘ab g i-ñeid g I’itoi ki: ḍ ‘o si ha’icu.
              <br />
              <br />
              <br />
              First we pray asking permission to gather.
              <br />
              Yucca, you stand beautifully in the grasses
              <br />
              I come to ask to take from you for my use.
              <br />
              I will take and leave you alone.
              <br />
              Your gift is beauty.
              <br />
              Bear grass, your growth is abundant.
              <br />
              You grow everywhere even in the city.
              <br />
              I want to cut from you for my use.
              <br />
              You will grow back more beautiful than before.
              <br />
              You, devil’s claw, you grow in my garden
              <br />
              The non-wild cousin whose flowers I anticipate.
              <br />
              You are beautiful in your ripeness.
              <br />
              I harvest your blackness to form beauty.
              <br />
              See here, O’odham dancing in a line
              <br />
              calling the rain.
              <br />
              See here a tortoise sits quietly and coyote
              <br />
              walks by leaving tracks.
              <br />
              See here, the squash blossom blooms in fullness
              <br />
              while butterflies flutter around.
              <br />
              Now see here, and look upon I’itoi’s house with respect.
              <br />
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
