import { Card } from "react-bootstrap";
import "./MissionGardenRender.css";
import Button from "react-bootstrap/Button";

export default function ASMRender() {

  var _paq = window._paq = window._paq || [];
  /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
  _paq.push(['trackPageView']);
  _paq.push(['enableLinkTracking']);
  (function() {
    var u="https://matomo.aws.coh.arizona.edu/";
    _paq.push(['setTrackerUrl', u+'matomo.php']);
    _paq.push(['setSiteId', '64']);
    var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
    g.async=true; g.src=u+'matomo.js'; s.parentNode.insertBefore(g,s);
  })();

  return (
    <>
      <div className="buttonContainer">
        <Button
          className="ARbuttonTmp"
          href="https://ar.blippar.com/w22ac1qn2n1rhkr2jcxts6ep4e45widb17fqcdrv">
          Experience AR
        </Button>
      </div>

      <div className="poem">
        <div className="cardContainer card">
          <div className="card-body">
            <div className="card-title h5"> Gardens have memories </div>
            <div className="mb-2 text-muted card-subtitle h6">
              By Dena Cowan <br />
            </div>
            <p className="card-text">
              Seeds sleep in the soil waiting for the right moment to wake up.
              <br />
              Yesterday’s roots feed today’s crops.
              <br />
              <br />
              A garden also holds the memories of its gardeners.
              <br />
              Feels the footsteps of the people who shaped it.
              <br />
              Echoes their lives.
              <br />
              <br />
            </p>
            <div className="card-title h6">花园的回忆</div>
            <div className="mb-2 text-muted card-subtitle h6">
              Chinese Translation by TCCC
              <br />
            </div>
            <p className="card-text">
              花园有回忆。
              <br />
              种子睡在土壤里,等待合适的时机醒来。
              <br />
              昨天的根为今天的庄稼提供食物。
              <br />
              <br />
              花园里还保存着园丁的回忆。
              <br />
              感受塑造它的人的脚步声。
              <br />
              回响他们的人生。
              <br />
              <br />
            </p>
            <div className="card-title h6">Recuerdos de un huerto</div>
            <div className="mb-2 text-muted card-subtitle h6">
              Spanish Translation by Dena Cowan
              <br />
            </div>
            <p className="card-text">
              Los huertos tienen recuerdos.
              <br />
              En la tierra duermen semillas esperando al momento preciso para
              despertarse.
              <br />
              Las raíces de ayer alimentan a las hortalizas de hoy.
              <br />
              <br />
              Un huerto también contiene los recuerdos de sus hortelanos.
              <br />
              Siente los pasos de la gente que lo labró.
              <br />
              Hace eco de sus vidas.
              <br />
              <br />
            </p>
            <div className="card-title h6">O’oidag ‘o ge cecegitoidag</div>
            <div className="mb-2 text-muted card-subtitle h6">
              O'odham Translation by Angelina Saraficio
              <br />
            </div>
            <p className="card-text">
              Ha’icu kai ‘o ko:oṣ gḍhu jeweḍ c’ed c ñenḍa mat hekid o ‘i-e-ai
              mat o i-wa:uwua
              <br />
              Ha-tatk ‘amjeḍ s-kokwog g e’es mo ‘ia cu:c.
              <br />
              <br />
              Oidag ñu:kuddam ‘o g ha-cecegĭtoidag ‘am ha’icug
              <br />
              Go:kĭ ‘o ‘id ‘amijeḍ ‘e-nantoḍ g ha- cecegĭtoidag.
              <br />
              <br />
            </p>
            <div className="card-title h6">Eechim Hitau Wate’u</div>
            <div className="mb-2 text-muted card-subtitle h6">
              Yoeme Translation by Felipe S. Molina
              <br />
            </div>
            <p className="card-text">
              Eechim ket hitau watine
              <br />
              Vachim bwia vetuk kokkoche vovitne hakwosa vem suavuvusanepo
              <br />
              Vat tukariapo naawam ian tovottawamta bwa’ame
              <br />
              <br />
              Wame eechim keta ameu watine havesa am echakan
              <br />
              Wame wokim inene havesa ama eechimpo naa kuaktisukame
              <br />
              Ama kukupapa vem ama hiapsisuka’apo
              <br />
              <br />
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
