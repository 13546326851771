import React from "react";
import { useRef } from "react";
import { Suspense, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { getStorage, getDownloadURL, ref } from "firebase/storage";
import { Link as ScrollLink } from "react-scroll";
import "./Homepage.css";
import Map from "../../components/Map/Map";
import emailjs from "@emailjs/browser";
import { motion } from "framer-motion";
import {
  getFirestore,
  collection,
  query,
  getDocs,
  docs,
  collectionId,
  listCollections,
} from "firebase/firestore";
import { generateSitePath } from "../../util/paths";
import LaunchIcon from "@mui/icons-material/Launch";

// Getting the graphics from Firebase was a pain in the ass so I'm just importing them manually for now.
import dunbar from "../../images/graphics/dunbarpavilion.png";
import tccc from "../../images/graphics/chineseculturalcenter.png";
import azmuseum from "../../images/graphics/arizonastatemuseum.png";
import pascuayaqui from "../../images/graphics/pascuayaqui.png";
import missiongarden from "../../images/graphics/missiongarden.png";
import borderlands from "../../images/graphics/borderlandstheatre.png";
import chd from "../../images/graphics/chinesehistorydowntown.png";
import tucsonchinesemarkets from "../../images/graphics/tucsonchinesemarkets.png";
import noImg from "../../images/noimage.jpg";
import ASMLogo from "../../images/logos/DCB-LogosASM.png";
import BorderlandsLogo from "../../images/logos/DCB-LogosBorderlands.png";
import DunbarLogo from "../../images/logos/DCB-LogosDunbar.png";
import IMLSLogo from "../../images/logos/DCB-LogosIMLS.png";
import MissionGardenLogo from "../../images/logos/DCB-LogosMission-Garden.png";
import PYLogo from "../../images/logos/DCB-LogosPY.png";
import AZHumanitiesLogo from "../../images/logos/DCB-LogosAZHumanities.png";
import TCCCLogo from "../../images/logos/DCB-LogosTCCC.png";
import UofALogo from "../../images/logos/DCB-LogosUofA.png";

// Site name placeholders until we connect to database.
const siteNames = [
  "Arizona State Museum",
  "Borderlands Theatre at Bonita Park",
  "Chinese History Downtown",
  "Dunbar Pavilion",
  "Mission Garden",
  "Tucson Chinese Cultural Center",
  "Tucson Chinese Markets",
  "Pascua Yaqui Tribe",
];

// For demo purposes, use above.
const siteNamesGraphicsDict = {
  "Arizona State Museum": azmuseum,
  "Borderlands Theatre At Bonita Park": borderlands,
  "Chinese History Downtown": chd,
  "Dunbar Pavilion": dunbar,
  "Mission Garden": missiongarden,
  "Tucson Chinese Cultural Center": tccc,
  "Tucson Chinese Markets": tucsonchinesemarkets,
  "Pascua Yaqui Tribe": pascuayaqui,
};

function Homepage() {
  // Initiating Firebase storage
  const storage = getStorage();
  const [siteOptions, setSiteOptions] = useState("");
  const [formSubmitButtonValue, setFormSubmitButtonValue] = useState("");
  const [formVal, setFormVal] = useState();

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  // Sending comment to email using Emailjs, https://www.emailjs.com/docs/examples/reactjs/
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_a2irjrp",
        "template_egf3kyi",
        form.current,
        "bqek4OFlM1dim_T_G"
      )
      .then(
        (result) => {
          setFormSubmitButtonValue("Successfully submitted!");
          setFormVal("");

          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  // Opens Center for Digital Humanities home page
  const handleClick = () => {
    window.open("https://digitalhumanities.arizona.edu", "_blank");
  };

  var siteSelectionElements = [];
  var i = 0;
  for (const [siteName, siteImgSrc] of Object.entries(siteNamesGraphicsDict)) {
    const sitePath = generateSitePath(siteName);
    const siteImage = (
      <img
        className="selectionImg"
        value={siteName}
        src={siteImgSrc === noImg ? noImg : siteImgSrc}
        alt={siteName}
      />
    );

    // Create the button with align/margins either left or right
    const displayLeft = i % 2 === 0;
    const marginSide = displayLeft ? "marginLeft" : "marginRight";
    const siteButton = (
      <button
        value={siteName}
        className="siteSelection"
        style={{
          alignItems: "center",
          justifyContent: "center",
          textAlign: displayLeft ? "left" : "right",
          [marginSide]: "5%",
        }}>
        {siteName}
      </button>
    );

    // Create the container for the image and button, taking the user to the site page
    siteSelectionElements.push(
      <Link to={sitePath}>
        <div className="selectionSubContainer">
          {displayLeft ? [siteImage, siteButton] : [siteButton, siteImage]}
        </div>
      </Link>
    );
    i++;
  }

  const projectManagement = [
    { name: "Lisa Falk", role: "Arizona State Museum" },
    { name: "Max Mijn", role: "Arizona State Museum" },
    { name: "Dr. Bryan Carter", role: "UA Center for Digital Humanities" },
  ];

  const ASM = [
    { name: "Lisa Falk" },
    { name: "Kathryn McFarland" },
    { name: "Max Mijn" },
    { name: "Jannelle Weakly" },
    { name: "Sarah Willwater" },
    { name: "Alice Wiley, Aranda/Lasch Architects" },
    {
      name: "Featured in AR:",
      role: "Terrol Dew Johnson, Amy Juan, Wak Tab Basket Dancers, Ofelia Zepeda",
    },
  ];

  const BorderlandsTheater = [
    { name: "Adam Cooper-Terán" },
    { name: "Marc Pinate" },
    { name: "Katharine Len Yee Mitchell" },
    {
      name: "Featured in AR:",
      role: "Diana Acosta, Clarisa Mungia",
    },
  ];

  const DunbarPavilion = [
    { name: "Barbara Lewis" },
    {
      name: "Featured in AR:",
      role: "Samuel E. Brown, Terry Thomas, Tucson Slide Society",
    },
  ];

  const MissionGarden = [
    { name: "Dena Cowan" },
    { name: "Kendall Kroesen" },
    {
      name: "Featured in AR:",
      role: "Maegan Lopez, Ruben Cu:k Ba'ak; voices of Felipe Molina, Jesús García, Victor Gee, Angela Saraficio, Elena Martin",
    },
  ];

  const PascalYaquiDepartment = [
    { name: "Daniel Vega" },
    { name: "Robert Rodriguez" },
  ];

  const TucsonChineseCulturalCenter = [
    { name: "Susan Chan" },
    { name: "Robin Blackwood" },
    { name: "Kathryn McFarland", role: "ASM" },
    { name: "Erica Castanoe", role: "UA Library Special Collections" },
    { name: "Dr. Laura Ng", role: "Grinnell College" },
    {
      name: "Featured in TCCC AR:",
      role: "Rita Cai, Jackson Veneklasen, Jasper Sorensen, class of Chen Ronji",
    },
    {
      name: "In CHD AR:",
      role: "Tina Liao, voice of Kathyrn MacFarland (ASM)",
    },
    {
      name: "Featured in Chinese Markets AR:",
      role: "Patsy Lee, Tun Lee, Dale Lopez, Carlos Alcantar, Penny Lee, Larry Lim",
    },
  ];

  const UAPoetryCenter = [{ name: "Leela Denver" }, { name: "Wren Goblirsch" }];

  const CDH = [
    { name: "Dr Bryan Carter" },
    { name: "Amelia Matheson" },
    { name: "Daniel Leon" },
    { name: "EJ Walker" },
    { name: "Gavin Voight" },
    { name: "Josh Tornquist" },
    { name: "Hamad Marhoon" },
    { name: "Andrew Waugaman" },
  ];

  var _paq = window._paq = window._paq || [];
  /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
  _paq.push(['trackPageView']);
  _paq.push(['enableLinkTracking']);
  (function() {
    var u="https://matomo.aws.coh.arizona.edu/";
    _paq.push(['setTrackerUrl', u+'matomo.php']);
    _paq.push(['setSiteId', '64']);
    var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
    g.async=true; g.src=u+'matomo.js'; s.parentNode.insertBefore(g,s);
  })();

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}>
      <div className="homepage">
        <div className="top1">
          <div className="messageContainer">
            <div className="headline">
              <a
                href="https://ar.blippar.com/15f2toxgeb0ipo8hxv3z1ykszazceu7sp2rc9jlm"
                target="_blank"
                className="introARButton">
                <span className="highlight-black">
                  Welcome
                  <LaunchIcon
                    fontSize="inherit"
                    style={{ marginLeft: "1vw", fontSize: "7vh" }}
                  />
                </span>
              </a>
            </div>
            <div className="subHeadline">
              <span className="highlight-black-full">
                Discover Community in the Borderlands through Augmented Reality
                Experiences throughout Tucson!
              </span>
            </div>
            <div className="buttons">
              <ScrollLink to="middle" smooth duration={500} offset={-150}>
                <button className="getStartedButton">Get started</button>
              </ScrollLink>
              <ScrollLink to="bottom" smooth duration={500} offset={-150}>
                <button className="learnMoreButton">Learn more</button>
              </ScrollLink>
            </div>
          </div>
        </div>

        <div className="middle">
          <div className="leftMiddle">
            <div className="sitesContainer">{siteSelectionElements}</div>
          </div>
          <div className="rightMiddle">
            <div className="mapContainer">
              <Map latitude={32.2} longitude={-110.99} zoom={10.4} />
            </div>
          </div>
        </div>

        <div className="bottom">
          <div className="leftBottom">
            <div className="aboutHeadline">
              <span className="highlight-orange">About</span>
            </div>
            <div className="aboutDescription">
              Discovering Community in the Borderlands is a unique Augmented
              Reality (AR) experience where visitors will learn about some of
              the diverse communities of Southern Arizona. A collaborative
              project of the University of Arizona (UA), partners include the
              Arizona State Museum, UA Center for Digital Humanities, UA Poetry
              Center, Dunbar Pavilion, Tucson Chinese Cultural Center, Pascua
              Yaqui Tribe’s Department of Language and Culture, Mission Garden,
              Borderlands Theater, and others. The project is funded by grants
              from the Institute for Museums and Library Services, the UA
              Library’s Andrew W. Mellon Digital Borderlands program, and Arizona
              Humanities.<br></br>
              <br></br>
              The partners are sharing stories of community history and culture
              through immersive AR experiences with culture specialists,
              tradition bearers, cultural objects, photographs, dance, music,
              poetry, plants and more. These digital exhibits are located
              outside at various sites across Tucson (see map). UA students at
              the{" "}
              <a href="#" onClick={handleClick}>
                {" "}
                Center for Digital Humanities{" "}
              </a>{" "}
              are working with curators and community specialists to create the
              AR experiences using such tools as 360 video, image overlays,
              two-dimensional video, and holograms. Together we are bringing to
              life the stories of our borderlands community.<br></br>
              <br></br>
              We hope you will visit each AR exhibit as well as returning to
              visit the related museums, gardens, and resource centers. Please
              also check out the poetry and writing prompts offered on the
              website, created in partnership with the University of Arizona
              Poetry Center. Please leave a comment to let us know how your AR
              experience was!<br></br> <br></br>
              {/* <img src={ASMlogo} alt="ASM Logo" className="logo-image" />
              <img src={CDHlogo} alt="CDH Logo" className="logo-image" />
              <img src={IMLSlogo} alt="IMLS Logo" className="logo-image" /> */}
              <br></br>
            </div>
          </div>
          <div className="rightBottom">
            <form className="commentForm" ref={form} onSubmit={sendEmail}>
              <span className="commentFormHeader">Leave a comment!</span>
              <input
                type="text"
                name="user_name"
                value={formVal}
                className="formInput"
                placeholder="Full name"
              />
              <input
                type="email"
                name="user_email"
                value={formVal}
                className="formInput"
                placeholder="Email"
              />
              <textarea
                name="message"
                value={formVal}
                placeholder="Comments or questions"
              />
              <input
                id="formSubmitButton"
                className="formSubmitButton"
                type="submit"
                value="Submit"
              />
              <span className="formSubmitButtonValue">
                {formSubmitButtonValue}
              </span>
            </form>
            <br></br>
          </div>
        </div>
        <div className="aboutSection">
          <div className="about-page-header">
            <div className="highlight-orange">Credits</div>
          </div>
          <div className="organizations">
            <div className="organization">
            <div class="highlight-blue">
              <p style={{ fontSize: "1.5rem", fontWeight: "bold" }}>
                Project Direction and Management
              </p>
              </div>
              {projectManagement.map((person) => {
                return (
                  <div className="aboutPerson">
                    <p className="aboutName">{person.name}</p>
                    <p className="aboutRoles">{person.role}</p>
                  </div>
                );
              })}
            </div>
            <div className="organization">
              <div class = "highlight-blue">
              <p style={{ fontSize: "1.5rem", fontWeight: "bold" }}>
                Arizona State Musuem
              </p>
              </div>
              {ASM.map((person) => {
                return (
                  <div className="aboutPerson">
                    <p className="aboutName">{person.name}</p>
                    <p className="aboutRoles">{person.role}</p>
                  </div>
                );
              })}
              <br />
              <br />
            </div>

            <div className="organization">
              <div class = "highlight-blue">
              <p style={{ fontSize: "1.5rem", fontWeight: "bold" }}>
                Borderlands Theatre
              </p>
              </div>
              {BorderlandsTheater.map((person) => {
                return (
                  <div className="aboutPerson">
                    <p className="aboutName">{person.name}</p>
                    <p className="aboutRoles">{person.role}</p>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="organizations">
          <div className="organization">
          <div class = "highlight-blue">
            <p style={{ fontSize: "1.5rem", fontWeight: "bold" }}>
              Dunbar Pavilion
            </p>
            </div>
            {DunbarPavilion.map((person) => {
              return (
                <div className="aboutPerson">
                  <p className="aboutName">{person.name}</p>
                  <p className="aboutRoles">{person.role}</p>
                </div>
              );
            })}
          </div>
          <div className="organization">
          <div class = "highlight-blue">
            <p style={{ fontSize: "1.5rem", fontWeight: "bold" }}>
              Mission Garden
            </p>
            </div>
            {MissionGarden.map((person) => {
              return (
                <div className="aboutPerson">
                  <p className="aboutName">{person.name}</p>
                  <p className="aboutRoles">{person.role}</p>
                </div>
              );
            })}
            <br />
            <br />
          </div>

          <div className="organization">
          <div class = "highlight-blue">
            <p style={{ fontSize: "1.5rem", fontWeight: "bold" }}>
              Pascua Yaqui Department of Language and Culture
            </p>
            </div>
            {PascalYaquiDepartment.map((person) => {
              return (
                <div className="aboutPerson">
                  <p className="aboutName">{person.name}</p>
                  <p className="aboutRoles">{person.role}</p>
                </div>
              );
            })}
          </div>
          <div className="organization">
            <div class = "highlight-blue">
            <p style={{ fontSize: "1.5rem", fontWeight: "bold" }}>
              UA Poetry Center
            </p>
            </div>
            {UAPoetryCenter.map((person) => {
              return (
                <div className="aboutPerson">
                  <p className="aboutName">{person.name}</p>
                  <p className="aboutRoles">{person.role}</p>
                </div>
              );
            })}
            <br />
            <br />
          </div>
        </div>
        <div className="organizations">
          <div className="organization">
          <div class = "highlight-blue">
            <p style={{ fontSize: "1.5rem", fontWeight: "bold" }}>
              Tucson Chinese Cultural Center
            </p>
            </div>
            {TucsonChineseCulturalCenter.map((person) => {
              return (
                <div className="aboutPerson">
                  <p className="aboutName">{person.name}</p>
                  <p className="aboutRoles">{person.role}</p>
                </div>
              );
            })}
          </div>

          <div className="organization">
            <div class="highlight-blue">
            <p style={{ fontSize: "1.5rem", fontWeight: "bold" }}>
              Web App and Website
            </p>
            </div>
            {CDH.map((person) => {
              return (
                <div className="aboutPerson">
                  <p className="aboutName">{person.name}</p>
                  <p className="aboutRoles">{person.role}</p>
                </div>
              );
            })}
          </div>
        </div>

        <div>
          <div style={{ margin: "auto", display: "flex", justifyContent: "center" }}>
            <img src={UofALogo} className="logo-image"></img>
            <div>
              <div style={{ justifyContent: "center", display:"flex", flexDirection:"column", height:"100%" }} >
                <div style={{ marginLeft: "20px"}} >Center for Digital Humanities</div>
                <div style={{ marginLeft: "20px"}} >Arizona State Museum</div>
                <div style={{ marginLeft: "20px"}} >Poetry Center</div>
                <div style={{ marginLeft: "20px"}} >University Libraries</div>
              </div>
            </div>
          </div>
          <div style={{ margin: "auto", display: "flex", justifyContent: "center" }}>
            <img src={ASMLogo} className="logo-image"></img>
            <img src={DunbarLogo} className="logo-image"></img>
            <img src={BorderlandsLogo} className="logo-image"></img>
            <img src={TCCCLogo} className="logo-image"></img>
          </div>
          <div style={{ margin: "auto", display: "flex", justifyContent: "center" }}>
            <img src={MissionGardenLogo} className="logo-image"></img>
            <img src={PYLogo} className="logo-image"></img>
            <img src={IMLSLogo} className="logo-image"></img>
            <img src={AZHumanitiesLogo} className="logo-image"></img>
          </div>
          <div style={{ margin: "auto", display: "flex", justifyContent: "center" }}>
          </div>
        </div>

        <div className="bottomFooter">
          <footer>
            © 2022 The Arizona Board of Regents on behalf of{" "}
            <a href="https://www.arizona.edu/" target="_blank">
              The University of Arizona
            </a>
            .
          </footer>
        </div>
      </div>
    </motion.div>
  );
}

export default Homepage;
