import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import logo from "../../images/logo.png";
import "./Navbar.css";
import Dropdown from "react-bootstrap/esm/Dropdown";
import { Button, DropdownButton } from "react-bootstrap";
import { generateSitePath } from "../../util/paths";

function Navbar() {
  // Site name placeholders until we connect to database.
  const siteNames = [
    "Arizona State Museum",
    "Borderlands Theatre At Bonita Park",
    "Chinese History Downtown",
    "Dunbar Pavilion",
    "Mission Garden",
    "Pascua Yaqui Tribe",
    "Tucson Chinese Cultural Center",
    "Tucson Chinese Markets",
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  // Displaying the various site names on the dropdown menu
  var siteNamesHTML = [];
  for (var i = 0; i < siteNames.length; i++) {
    siteNamesHTML.push(
      <Dropdown.Item
        as={Link}
        to={generateSitePath(siteNames[i])}
        key={siteNames[i]}>
        {siteNames[i]}
      </Dropdown.Item>
    );
  }

  var activitySites = [];
  activitySites.push(
    <Dropdown.Item
      as={Link}
      to={generateSitePath("The Poetry Center")}
      key={"The Poetry Center"}>
      {"The Poetry Center"}
    </Dropdown.Item>
  );

  return (
    <nav className="navbar">
      <Link to="/" className="navbar-logo">
        <img alt="logo" className="navbar-logo" src={logo} />
      </Link>

      {/* <DropdownButton className="writingActivities" title="Writing Activities">
        {activitySites}
      </DropdownButton> */}
      <div style={{ display: "flex", flexDirection: "row", gap: "1vw" }}>
        <Button
          className="writingActivities"
          title="Writing Activities"
          href="/WritingActivities">
          <p style={{ marginTop: "7%" }}>Writing Activities</p>
        </Button>

        <DropdownButton title="Explore Sites">{siteNamesHTML}</DropdownButton>
      </div>
    </nav>
  );
}
export default Navbar;
