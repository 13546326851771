import "./Map.css";
import React, { useEffect, useState } from "react";
import MapGL, { GeolocateControl, Marker, NavigationControl, Popup } from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import mapboxgl from "mapbox-gl";
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { getFirestore, collection, query, getDocs } from "firebase/firestore";
import { motion } from "framer-motion";
import { useParams } from "react-router-dom";

mapboxgl.accessToken = 'pk.eyJ1IjoiZGNiLXdlYnNpdGUiLCJhIjoiY2w5eGFwanc0MDNqdzN1cGNqaXk0NHljZSJ9.ynHhk1K9FeZHrZ_7z9S7qw';
// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

function Map(props) {

  const [showPopup, setShowPopup] = useState(null);
  const [locations, setLocations] = useState([]);
  const { siteName: siteIdentifier } = useParams();

  const lng = props.longitude;
  const lat = props.latitude;
  const zm = props.zoom;

  const [viewport, setViewport] = useState({
    longitude: lng,
    latitude: lat,
    zoom: zm,
    width: "100vw",
    height: "100vh"

  })

  const firestore = getFirestore();

  const collections = query(
    collection(firestore, "partners"),
  );

  useEffect(() => {
    const getLocations = async () => {
      const data = await getDocs(collections);
      let dataDocs = data.docs.map((doc) => ({ ...doc.data() }));
      let locs = [];
      if (siteIdentifier == undefined){
        locs = dataDocs;
      } else {
        let site = siteIdentifier.toLowerCase();
        for (let i=0; i<dataDocs.length; i++){
          if (site == dataDocs[i]["id"]){
            locs.push(dataDocs[i]);
          }
        }
      }
      setLocations(locs);
    };
    getLocations();
  }, []);
  

  useEffect(() => {
    const listener = e => {
      if (e.key === "Escape") {
        setShowPopup(null);
      }
    };
    window.addEventListener("keydown", listener);

    return () => {
      window.removeEventListener("keydown", listener);
    };
  }, []);

  return (
    <motion.div initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 1 }}>
      <div className="mapContainer">

        <MapGL
          initialViewState={{
            longitude: lng,
            latitude: lat,
            zoom: zm,
          }}
          //{...viewport}
          style={{ width: '100%', borderRadius: '3%' }}
          
          //mapStyle="mapbox://styles/dcb-website/cl33nq49o000015uipjspnv2n"
          mapStyle="mapbox://styles/mapbox/outdoors-v12"
        //onViewportChange={viewport => {
        //setViewport(viewport);
        //}}
        >
          {locations.map((location, i) => (
            <Marker
              key={location.id + i}
              latitude={location.latitude}
              longitude={location.longitude}>

              <button className="marker-button"
                onClick={e => {
                  e.preventDefault();
                  setShowPopup(location);
                }}>
                <img src="https://cdn0.iconfinder.com/data/icons/small-n-flat/24/678111-map-marker-512.png"
                  width='40'
                  height='40'
                  alt='pin' />
              </button>

            </Marker>
          ))}

          {showPopup ? (
            
            <Popup latitude={showPopup.latitude}
              longitude={showPopup.longitude}
              key={showPopup.key}
              closeButton={true}
              closeOnClick={false}
              onClose={() => {
                setShowPopup(null);
              }}
              className='card-style'
              anchor="left"
            >
              <img className="image-style" src={showPopup.imgLink} />

              <Typography gutterBottom variant="h6" color="black" fontFamily={"Open sans"} fontStyle={"sans-serif"}>
                {showPopup.Name != "Tucson Chinese Markets" ? showPopup.Name : "Tucson Chinese Markets - Lim You"}
              </Typography>
              <Typography variant="body2" color="text.secondary" style={{marginBottom: '5%'}}>
                {showPopup.Address}
              </Typography>
              <Button size="small" variant="outlined" href={showPopup.directions} className="button1" target="_blank"
                fontFamily={"Open sans"} fontStyle={"sans-serif"}>Directions</Button>
              <Button size="small" variant="outlined" href={showPopup.Link} className="button2" target="_blank"
                fontFamily={"Open sans"} fontStyle={"sans-serif"}>Website</Button>
            </Popup>
            
          ) : null}

          <NavigationControl />
        </MapGL>

      </div>
    </motion.div>
  )
}

export default Map;