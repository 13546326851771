import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { initializeApp } from 'firebase/app';
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyCb_GqeWXo2E-sKg2MXlBbtxAOpGibiZYA",
  authDomain: "dcb-project.firebaseapp.com",
  databaseURL: "https://dcb-project-default-rtdb.firebaseio.com",
  projectId: "dcb-project",
  storageBucket: "dcb-project.appspot.com",
  messagingSenderId: "1033533382493",
  appId: "1:1033533382493:web:c5ad922fc31fefc54d92b4"
};

const app = initializeApp(firebaseConfig);
const storage = getStorage();
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);
