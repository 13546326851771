import limyouPic from "../../images/graphics/LimYou sign final.jpeg";

function Limyou(){
    return (
        <div style={{ width:"100vw", height:"100vh" }}>
            <img src={limyouPic} style={{ width:"auto", height:"50vh", position:"relative", top:"200px", display:"block", margin:"auto" }}></img>
        </div>
    );
}

export default Limyou;