import Gallery from "react-photo-gallery";
import { Card } from "react-bootstrap";
import React, { useCallback } from "react";
import "./DunbarRender.css";
import dunbar1 from "../../../images/dunbarImages/dunbar1.jpeg";
import dunbar2 from "../../../images/dunbarImages/dunbar2.jpeg";
import dunbar3 from "../../../images/dunbarImages/dunbar3.jpeg";
import dunbar4 from "../../../images/dunbarImages/dunbar4.jpeg";
import dunbar5 from "../../../images/dunbarImages/dunbar5.jpeg";
import dunbar6 from "../../../images/dunbarImages/dunbar6.jpeg";
import dunbar7 from "../../../images/dunbarImages/dunbar7.jpeg";
import dunbar8 from "../../../images/dunbarImages/dunbar8.jpeg";
import dunbar9 from "../../../images/dunbarImages/dunbar9.jpeg";
import dunbar10 from "../../../images/dunbarImages/dunbar10.jpeg";

export default function DunbarRender() {

  const dunbarPics = [
    {
      src: dunbar1,
      width: 10,
      height: 7,
    },
    {
      src: dunbar2,
      width: 7,
      height: 10,
    },
    {
      src: dunbar3,
      width: 8,
      height: 10,
    },
    {
      src: dunbar4,
      width: 8,
      height: 10,
    },
    {
      src: dunbar5,
      width: 9,
      height: 10,
    },
    {
      src: dunbar6,
      width: 10,
      height: 9,
    },
    {
      src: dunbar7,
      width: 11,
      height: 9,
    },
    {
      src: dunbar8,
      width: 10,
      height: 8,
    },
    {
      src: dunbar9,
      width: 7,
      height: 10,
    },
    {
      src: dunbar10,
      width: 10,
      height: 8,
    },
  ];
  
  const dunbarMetadata = [
    "Dunbar School Christmas Play, Sadie Turner (Third from the left in top row), Donald Lander (Second from right in top row), 12/1/1947",
    "Dunbar Majorettes, Left to right: Selma Euell, Wilma Shaw, Jean Euell, 1/1/1941",
    "Dunbar School students seranade Mrs. Phyllis Sanders at her home at 378 N. Main st. on Christmas, 1941, date unknown",
    "Dunbar Jr High Drum and Bugle Corps, Mona Mouton (Front, center), 2/1/1946",
    "1936 Dunbar graduates dressed for their graduation, Left to Right: Marguerite Euell, Joseph Euell 5/1/1936",
    "Front Left, Della Devereaux, front right, one of the Fleming twins, date unknown, likely 1947 or 1947",
    "date unknown",
    "date unknown",
    `Pictured are Shirley Robinson and Ernie McCray, "the whiz kids." Unkown person has written on back of the print "Taken in front of 'Dew Drop Inn' between church services at mt. Calvary Church - We competed on radio shows against white students - 4th gr. -  Mrs Daniel's class. The prize we sought was a radio.", date unknown`,
    "date unknown",
  ];

  var _paq = window._paq = window._paq || [];
  /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
  _paq.push(['trackPageView']);
  _paq.push(['enableLinkTracking']);
  (function() {
    var u="https://matomo.aws.coh.arizona.edu/";
    _paq.push(['setTrackerUrl', u+'matomo.php']);
    _paq.push(['setSiteId', '64']);
    var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
    g.async=true; g.src=u+'matomo.js'; s.parentNode.insertBefore(g,s);
  })();

  const cont = {
    overflow: "hidden",
    position: "relative",
  };

  const GalleryImage = ({ photo, margin, direction, top, left, index }) => {
    if (direction === "column") {
      cont.position = "absolute";
      cont.left = left;
      cont.top = top;
    }

    return (
      <div
        style={{
          margin,
          // width: "24%",
          ...cont,
        }}>
        <img
          alt={photo.title}
          {...photo}
          src={photo.src}
          title={dunbarMetadata[index]}
        />
      </div>
    );
  };

  const imageRenderer = useCallback(
    ({ index, left, top, key, photo }) => (
      <GalleryImage
        key={index}
        margin={"5px"}
        index={index}
        photo={photo}
        left={left}
        top={top}
      />
    ),
    []
  );

  return (
    <div>
      <div className="lowerContainer">
        <div className="poem">
          <Card className="cardContainer">
            <Card.Body>
              <Card.Title style={{ fontSize: 35 }}>
                {" "}
                Dunbar: A Grand Old School Was She{" "}
              </Card.Title>
              <Card.Subtitle className="mb-2 text-muted">
                By Ernie McCray <br />
                Originally published on October 12, 2021 in the OB Rag <br />
              </Card.Subtitle>
              <Card.Text style={{ fontSize: 15 }}>
                <br />
                I just ran across an article about an old friend from when I was
                growing up in Tucson.
                <br />
                <br />
                Among so many things I found interesting in the piece was
                something he said about a place I hold dear:
                <br />
                <br />
                Dunbar. The “Colored” school.
                <br />
                <br />
                He said: “I’m sorry I didn’t get a good education."
                <br />
                <br />
                And I hear him, somewhat, considering that our academic
                experiences at Dunbar were way more “Read Chapter Three and
                answer the questions when you’re done” than was my cup of tea –
                but that summarizes pretty much all of my education, from
                kindergarten through graduate school, to a large degree.
                <br />
                <br />
                But I look at my Dunbar days in a different way. I mean I
                started school there when I was in fourth grade, an escapee from
                Blessed Martin de Porres Catholic School that was run by a nun
                who made Attila the Hun seem like a teddy bear.
                <br />
                <br />
                She looked at us students like a lioness assessing her prey.
                <br />
                <br />
                And she, via her yardstick, let me know that she didn’t
                appreciate me in any way.
                <br />
                <br />
                So, when I got to Dunbar, I thought I had entered the gates of
                heaven. I’ve never received more love in any one place than what
                I basked in there.
                <br />
                <br />
                I was hardly on the campus a month before I won a War Bond, for
                a story I penned about a Dodo bird for some scholastic
                publication. That did a whole lot for me when it comes to my
                education.
                <br />
                <br />
                Then the next thing I knew I was representing the school, with
                other bright students, in a radio quiz show competition, which
                let me know in my young mind that whoever came up with the
                notion that Black folks were dumb was completely out of their
                mind.
                <br />
                <br />
                That ended any chance that I would ever buy into the notion of
                “White supremacy.”
                <br />
                <br />
                How many people can say they got that from their schooling in
                the 20th Century?
                <br />
                <br />
                I learned a little bit of everything at Dunbar. With my early
                images of life having been scenes of war and rations and “No
                Colored” signs, I was already wondering about people around the
                world during those times – and then I find myself sitting with
                my class wearing Chinese attire in a multi-cultural celebration
                where each class is dressed in costumes from nations around the
                globe.
                <br />
                <br />
                Made me dream that there could be a different reality, harmony,
                maybe, throughout humanity. That dream has lasted for decades.
                <br />
                <br />
                When I remember Dunbar, I hear the sweet voices of our award
                winning choir; the tapping, in a parade, of our equally award
                winning marching band and majorettes’ feet on the street to a
                marching beat; doo-wop harmonizing and boogie-woogie playing at
                assemblies.
                <br />
                <br />
                I see our sports teams playing colorfully and assuredly, among
                the finest in the city.
                <br />
                <br />
                I remember vividly how we, generally, tried to find and make our
                way, carrying the legacy of a people once enslaved, as I hear a
                friend, struggling to hold his rage at bay, saying to somebody
                trying to block his way:
                <br />
                <br />
                “You better be yo self, Jack!” cuz I might be a sober man, and
                if I am a sober man, I will f—k you up!”
                <br />
                <br />
                Another image comes up of a friend reflecting what’s up with his
                family’s financial situation with:
                <br />
                <br />
                “Listen here, man, if it took a penny to go around the world I
                couldn’t get out of sight.”
                <br />
                <br />
                Based on my own family’s similar plight, I knew he was right.
                <br />
                <br />
                There were a lot of things back then we had yet to get right as
                I recall a day when my generation’s sexism came to light, a day
                when a girl, a neighbor of mine (who was truly “fine”) was
                wearing a “Why me?” expression on her face, having to tolerate:
                <br />
                <br />
                “Look here, grrl, I’m go’ throw all my money in the air, and
                what stays up is mine, and what hits the ground is yours! You
                unnerstan’ what I’m sayin’?”
                <br />
                <br />
                To, which she replies, “What am I supposed to do with all those
                pennies, fool?” unable to hide the feeling of “I’m so tired of
                this drool” that showed in her eyes.
                <br />
                <br />
                Hey, all that was our life and somewhere, in the midst of all
                that, a number of teachers who loved and appreciated me enough
                to let me be me somehow provided the 3 R’s I needed to make my
                way in this upside-down society.
                <br />
                <br />
                And they sold me on a motto, “Be the Best,” that has guided me
                throughout my life.
                <br />
                <br />
                Not in the sense of being better than anybody else but being my
                best self. I think I’m probably forever grateful to the school
                for that above all else.
                <br />
                <br />
                As we used to sing: “Hail to Dunbar Jr. High! A grand old school
                is she!”
                <br />
                <br />
                <br />
                Yes, she was.
              </Card.Text>
            </Card.Body>
          </Card>
        </div>
        <div>
          <br />
          <Gallery photos={dunbarPics} targetRowHeight={405} margin={5} renderImage={imageRenderer}/>
        </div>
      </div>
    </div>
  );
}
