import "./DetailsPage.css";
import React, { Suspense, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import Map from "../../components/Map/Map";
import { getStorage, getDownloadURL, ref } from "firebase/storage";
import {
  getFirestore,
  collection,
  query,
  getDocs,
  docs,
  collectionId,
  listCollections,
} from "firebase/firestore";
import { initializeApp } from "firebase/app";
import { motion } from "framer-motion";
import Button from "react-bootstrap/Button";
import DunbarRender from "../../components/custom-renders/DunbarRender/DunbarRender";
import BorderlandsRender from "../../components/custom-renders/BorderlandsRender/BorderlandsRender";
import ASMRender from "../../components/custom-renders/ASMRender/ASMRender";
import ASMGallery from "../../components/custom-renders/ASMRender/ASMGallery";
import MissionGardenRender from "../../components/custom-renders/MissionGardenRender/MissionGardenRender";
import CHDRender from "../../components/custom-renders/CHDRender/CHDRender";
import TucsonChineseMarketsRender from "../../components/custom-renders/TucsonChineseMarkets/TucsonChineseMarkets";
import TCCCRender from "../../components/custom-renders/TCCCRender/TCCCRender";

const firebaseConfig = {
  apiKey: "AIzaSyCb_GqeWXo2E-sKg2MXlBbtxAOpGibiZYA",
  authDomain: "dcb-project.firebaseapp.com",
  databaseURL: "https://dcb-project-default-rtdb.firebaseio.com",
  projectId: "dcb-project",
  storageBucket: "dcb-project.appspot.com",
  messagingSenderId: "1033533382493",
  appId: "1:1033533382493:web:c5ad922fc31fefc54d92b4",
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);

function DetailsPage() {
  // Initiating Firebase
  const firestore = getFirestore();

  // Initiating Firebase storage
  const storage = getStorage();

  const { siteName: siteIdentifier } = useParams();
  console.log("SITE IDENTIFIER " + siteIdentifier);

  const [graphicURL, setGraphicURL] = useState("");
  const [description, setDescription] = useState("");
  const [arDescription, setARDescription] = useState("");
  const [hyperlink, setHyperlink] = useState("");
  const [siteName, setSiteName] = useState("");
  const [id, setId] = useState("");
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [arLink, setArLink] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  useEffect(() => {
    async function fetchData() {
      const collections = query(collection(firestore, "partners"));
      const querySnapshot = await getDocs(collections);

      console.log("LOWERCASE SITE IDENTIFIER: " + siteIdentifier.toLowerCase());

      querySnapshot.forEach((snap) => {
        console.log(snap.data().id + " " + siteIdentifier.toLowerCase());
        if (snap.data().id == siteIdentifier.toLowerCase()) {
          const docData = snap.data();
          setSiteName(docData["Name"] || "");
          setARDescription(docData["AR Description"] || "");
          setDescription(docData["About"] || "");
          setHyperlink(docData["Link"] || "");
          setId(docData["id"] || "");
          setLatitude(docData["latitude"] || "");
          setLongitude(docData["longitude"] || "");
          setArLink(docData["arLink"] || "");
        }
      });
    }
    fetchData();
  }, [siteIdentifier]);

  useEffect(() => {
    // Fetching Graphic images from Firebase Storage
    var graphicPath = siteName.replace(/\s+/g, "");
    if (id !== "") {
      getDownloadURL(ref(storage, "graphics/" + id + ".png"))
        .then((url) => {
          setGraphicURL(url);
        })
        .catch((error) => {
          // Handle any errors
        });
    }
  }, [siteName, id, storage]);

  function buttonReturn() {
    if (id === "arizonastatemuseum") {
      return <ASMRender />;
    }
    if (id === "borderlandstheatreatbonitapark") {
      return <BorderlandsRender />;
    }
    if (id === "missiongarden") {
      return <MissionGardenRender />;
    }
    if (id === "tucsonchineseculturalcenter") {
      return <TCCCRender />;
    }
    if (id === "tucsonchinesemarkets") {
      return <TucsonChineseMarketsRender />;
    }

    if (arLink.length > 1) {
      return (
        <Button className="ARbuttonWorks" href={arLink} target="_blank">
          Experience AR
        </Button>
      );
    }
    if (arLink.length < 1) {
      return (
        <Button className="ARbuttonTmp" href={arLink} target="_blank">
          AR Coming Soon!
        </Button>
      );
    }
  }

  var _paq = window._paq = window._paq || [];
  /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
  _paq.push(['trackPageView']);
  _paq.push(['enableLinkTracking']);
  (function() {
    var u="https://matomo.aws.coh.arizona.edu/";
    _paq.push(['setTrackerUrl', u+'matomo.php']);
    _paq.push(['setSiteId', '64']);
    var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
    g.async=true; g.src=u+'matomo.js'; s.parentNode.insertBefore(g,s);
  })();

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 1 }}>
      <div className="details">
        <div className="top">
          <div className="detailsMessageContainer">
            {/* {img1 && <img src={img1} alt="Site Logo" className="logo-image" />} */}

            <div className="detailsHeadline">
              <span className="highlight-blue">{siteName}</span>
            </div>
            <div className="detailsPartition"></div>
            <div className="detailsSubHeadline">{description}</div>
          </div>
          <div className="graphicContainer">
            <img className="detailsGraphic" src={graphicURL}></img>
          </div>
        </div>
        <div className="bottom">
          <div className="instructionsContainer">
            <div className="detailsHeadline">
              <span className="highlight-orange">AR Experience</span>
            </div>
            <br></br>
            <div className="detailsSubHeadline">{arDescription}</div>
            <div className="detailsButton">{buttonReturn()}</div>

            <div className="detailsSubHeadline">
              <a className="websiteLink" href={hyperlink} target="_blank">
                Visit the {siteName} Website
              </a>
            </div>
          </div>
          <div className="outerMapContainer">
            <div className="detailsMapContainer">
              <Map
                className="map"
                key={id}
                latitude={latitude}
                longitude={longitude}
                zoom={id != "tucsonchinesemarkets" ? 14.0 : 12.5}></Map>
            </div>
          </div>
        </div>
        <div>
          {id === "dunbarpavilion" && <DunbarRender />}
          {id === "chinesehistorydowntown" && <CHDRender />}
          {id === "arizonastatemuseum" && <ASMGallery />}
        </div>
        <div className="bottomFooter">
          <footer>
            © 2022 The Arizona Board of Regents on behalf of{" "}
            <a href="https://www.arizona.edu/" target="_blank">
              The University of Arizona
            </a>
            .
          </footer>
        </div>
      </div>
    </motion.div>
  );
}

export default DetailsPage;
