import Gallery from "react-photo-gallery";
import "./ASMRender.css";
import pottery1 from "../../../images/potteryPictures/Agave Fiber 02.jpg";
import pottery2 from "../../../images/potteryPictures/Chicago architecture biennial 01.jpg";
import pottery3 from "../../../images/potteryPictures/Cloud Weave.jpg";
import pottery4 from "../../../images/potteryPictures/Copper Coil 03 .jpg";
import pottery5 from "../../../images/potteryPictures/Desert Paper 05.jpg";
import pottery6 from "../../../images/potteryPictures/Desert Paper 07.jpg";
import pottery7 from "../../../images/potteryPictures/Desert Paper 09.jpg";
import pottery8 from "../../../images/potteryPictures/Desert Paper 11.jpg";
import pottery9 from "../../../images/potteryPictures/Desert Paper 18 .jpg";
import pottery10 from "../../../images/potteryPictures/Form Over Function.jpg";
import pottery11 from "../../../images/potteryPictures/Gourd basket.jpg";
import pottery12 from "../../../images/potteryPictures/Grass Coil 01.jpg";
import pottery13 from "../../../images/potteryPictures/Grass Coil 02.jpg";
import pottery14 from "../../../images/potteryPictures/Horse Hair Coil 02.jpg";
import pottery15 from "../../../images/potteryPictures/Horse Hair and Wood 02.jpg";
import pottery16 from "../../../images/potteryPictures/Inlaid Gourd Basket.jpg";
import pottery17 from "../../../images/potteryPictures/Knot 03 .jpg";
import pottery18 from "../../../images/potteryPictures/Meeting The Clouds Halfway 01.jpg";
import pottery19 from "../../../images/potteryPictures/Meeting The Clouds Halfway 02.jpg";
import pottery20 from "../../../images/potteryPictures/The Conversation.jpg";
import pottery21 from "../../../images/potteryPictures/Where the Clouds Live.jpg";
import pottery22 from "../../../images/potteryPictures/Wire Coil 06 with Yucca .jpg";
import pottery23 from "../../../images/potteryPictures/Wood Basket 02.jpg";
import React, { useState, useEffect, useCallback } from "react";

export default function ASMGallery() {

  var _paq = window._paq = window._paq || [];
  /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
  _paq.push(['trackPageView']);
  _paq.push(['enableLinkTracking']);
  (function() {
    var u="https://matomo.aws.coh.arizona.edu/";
    _paq.push(['setTrackerUrl', u+'matomo.php']);
    _paq.push(['setSiteId', '64']);
    var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
    g.async=true; g.src=u+'matomo.js'; s.parentNode.insertBefore(g,s);
  })();

  const potteryPics = [
    {src: pottery1,},
    {src: pottery2,},
    {src: pottery3,},
    {src: pottery4,},
    {src: pottery5,},
    {src: pottery6,},
    {src: pottery7,},
    {src: pottery8,},
    {src: pottery9,},
    {src: pottery10,},
    {src: pottery11,},
    {src: pottery12,},
    {src: pottery13,},
    {src: pottery14,},
    {src: pottery15,},
    {src: pottery16,},
    {src: pottery17,},
    {src: pottery18,},
    {src: pottery19,},
    {src: pottery20,},
    {src: pottery21,},
    {src: pottery22,},
    {src: pottery23,},
  ];

  const [photos, setPhotos] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  console.log(potteryPics)

  const getImageDimensions = async (file, index) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = file;
      img.onload = () => {
        const { naturalWidth: width, naturalHeight: height } = img;
        resolve({ width, height });
      };
      img.onerror = reject;
    });
  };

  useEffect(() => {
    Promise.all(
      potteryPics.map((image, i) =>
        getImageDimensions(image.src, i).then((dims) => ({ ...image, ...dims }))
      )
    ).then((photos) => {
      setPhotos(photos);
      setIsLoading(false); // Set loading to false after images have been loaded
    });
  }, []);

  const cont = {
    overflow: "hidden",
    position: "relative",
  };

  const GalleryImage = ({ photo, margin, direction, top, left, index }) => {
    if (direction === "column") {
      cont.position = "absolute";
      cont.left = left;
      cont.top = top;
    }
    console.log(photo)

    return (
      <div
        style={{
          margin,
          // width: "24%",
          ...cont,
        }}>
        <img
          alt={photo.title}
          {...photo}
          src={photo.src}
          title="Courtesy Aranda\Lasch Architects"
        />
      </div>
    );
  };

  const imageRenderer = useCallback(
    ({ index, left, top, key, photo }) => (
      <GalleryImage
        key={index}
        margin={"5px"}
        index={index}
        photo={photo}
        left={left}
        top={top}
      />
    ),
    []
  );

  return (
    <>
      {isLoading ? (
        <div></div>
      ) : (
      <div>
        <br />
        <Gallery photos={photos} margin={5} renderImage={imageRenderer}/>
      </div>)}
    </>
  );
}
