import empirePic from "../../images/graphics/Empire sign final.jpeg";

function Empire(){
    return (
        <div style={{ width:"100vw", height:"100vh" }}>
            <img src={empirePic} style={{ width:"auto", height:"50vh", position:"relative", top:"200px", display:"block", margin:"auto" }}></img>
        </div>
    );
}

export default Empire;