import alansPic from "../../images/graphics/Alans sign final.jpeg";

function Alans(){
    return (
        <div style={{ width:"100vw", height:"100vh" }}>
            <img src={alansPic} style={{ width:"auto", height:"50vh", position:"relative", top:"200px", display:"block", margin:"auto" }}></img>
        </div>
    );
}

export default Alans;