import { Card } from "react-bootstrap";
import "./TucsonChineseMarkets.css";
import Button from "react-bootstrap/Button";
import alansPic from "../../../images/graphics/Alans sign final.jpeg";
import empirePic from "../../../images/graphics/Empire sign final.jpeg";
import limyouPic from "../../../images/graphics/LimYou sign final.jpeg";
import React, { useState, useEffect, useCallback } from "react";
import Gallery from "react-photo-gallery";

export default function TucsonChineseMarketsRender() {

  var _paq = window._paq = window._paq || [];
  /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
  _paq.push(['trackPageView']);
  _paq.push(['enableLinkTracking']);
  (function() {
    var u="https://matomo.aws.coh.arizona.edu/";
    _paq.push(['setTrackerUrl', u+'matomo.php']);
    _paq.push(['setSiteId', '64']);
    var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
    g.async=true; g.src=u+'matomo.js'; s.parentNode.insertBefore(g,s);
  })();

  const potteryPics = [
    {src: limyouPic,},
    {src: empirePic,},
    {src: alansPic,},
  ];

  const [photos, setPhotos] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  console.log(potteryPics)

  const getImageDimensions = async (file, index) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = file;
      img.onload = () => {
        const { naturalWidth: width, naturalHeight: height } = img;
        resolve({ width, height });
      };
      img.onerror = reject;
    });
  };

  useEffect(() => {
    Promise.all(
      potteryPics.map((image, i) =>
        getImageDimensions(image.src, i).then((dims) => ({ ...image, ...dims }))
      )
    ).then((photos) => {
      setPhotos(photos);
      setIsLoading(false); // Set loading to false after images have been loaded
    });
  }, []);

  const cont = {
    overflow: "hidden",
    position: "relative",
  };

  const GalleryImage = ({ photo, margin, direction, top, left, index }) => {
    if (direction === "column") {
      cont.position = "absolute";
      cont.left = left;
      cont.top = top;
    }
    console.log(photo)

    return (
      <div
        style={{
          margin,
          // width: "24%",
          ...cont,
        }}>
        <img
          alt={photo.title}
          {...photo}
          src={photo.src}
        />
      </div>
    );
  };

  const imageRenderer = useCallback(
    ({ index, left, top, key, photo }) => (
      <GalleryImage
        key={index}
        margin={"5px"}
        index={index}
        photo={photo}
        left={left}
        top={top}
      />
    ),
    []
  );

  return (
    <>
      <div className="buttonContainer">
        <Button
          className="ARbuttonWorks"
          href={"/LimyouSign"}
          target="_blank">
          Experience AR: Lim You Market
        </Button>
        <Button
          className="ARbuttonWorks"
          href={"/EmpireSign"}
          target="_blank">
          Experience AR: Empire Food​s Market
        </Button>
        <Button
          className="ARbuttonWorks"
          href={"/AlansSign"}
          target="_blank">
          Experience AR: Alan's Market
        </Button>
      </div>
      {/*isLoading ? (
        <div></div>
      ) : (
      <div>
        <br />
        <Gallery photos={photos} margin={5} renderImage={imageRenderer}/>
      </div>)*/}
    </>
  );
}
