import Gallery from "react-photo-gallery";
import { Card } from "react-bootstrap";
import "./CHDRender.css";
import chd1 from "../../../images/chdImages/DCB_Site06-CHD_Region-Map.png";
import chd2 from "../../../images/chdImages/c-2889_scan.png";
import chd3 from "../../../images/chdImages/DCB_Site06-CHD_Block-Map.png";
import chd4 from "../../../images/chdImages/DCB_Site06-CHD_Village-Map.png";
import chd5 from "../../../images/chdImages/azu_ms376_box1_fl15_013.png";
import chd6 from "../../../images/chdImages/azu_ms376_box1_fl13_009.png";
import chd7 from "../../../images/chdImages/azu_ms376_box19_fl13_019.png";
import chd8 from "../../../images/chdImages/azu_ms376_box1_fl17_016.png";
import chd9 from "../../../images/chdImages/DCB_Site06-CHD_IMG-GAL-Translation.png";

const chdPics = [
  {
    src: chd1,
    width: 10,
    height: 8,
  },
  {
    src: chd2,
    width: 12,
    height: 8,
  },
  {
    src: chd3,
    width: 13,
    height: 17,
  },
  {
    src: chd4,
    width: 10,
    height: 11,
  },
  {
    src: chd5,
    width: 10,
    height: 7,
  },
  {
    src: chd6,
    width: 13,
    height: 16,
  },
  {
    src: chd7,
    width: 15,
    height: 10,
  },
  {
    src: chd8,
    width: 12,
    height: 5,
  },
  {
    src: chd9,
    width: 12,
    height: 16,
  },
];

export default function CHDRender() {

  var _paq = window._paq = window._paq || [];
  /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
  _paq.push(['trackPageView']);
  _paq.push(['enableLinkTracking']);
  (function() {
    var u="https://matomo.aws.coh.arizona.edu/";
    _paq.push(['setTrackerUrl', u+'matomo.php']);
    _paq.push(['setSiteId', '64']);
    var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
    g.async=true; g.src=u+'matomo.js'; s.parentNode.insertBefore(g,s);
  })();

  return (
    <div>
      <div>
        <br />
        <Gallery photos={chdPics} targetRowHeight={405} margin={5} />
      </div>
    </div>
  );
}
