import "./WritingActivities.css";
import Iframe from "react-iframe";
import React from "react";
import ASM from "../../images/writingActivities/DCB_Writing-Activity-imgs_ASM.png";
import Dunbar from "../../images/writingActivities/DCB_Writing-Activity-imgs_Dunbar.png";
import Borderlands from "../../images/writingActivities/DCB_Writing-Activity-imgs_Borderlands.png";
import MissionGarden from "../../images/writingActivities/DCB_Writing-Activity-imgs_Mission-Garden.png";
import CHD from "../../images/writingActivities/DCB_Writing-Activity-imgs_CHD.png";
import TCCC from "../../images/writingActivities/DCB_Writing-Activity-imgs_TCCC.png";
import ASMPDF from "../../components/WritingActivities/WritingActivitiesFiles/DCB_Site05-ASM_Postcard.pdf"
import DunbarPDF from "../../components/WritingActivities/WritingActivitiesFiles/DCB_Site01-Dunbar_Postcard.pdf"
import BorderlandsPDF from "../../components/WritingActivities/WritingActivitiesFiles/DCB_Site03-BT_Postcard.pdf"
import MissionGardenPDF from "../../components/WritingActivities/WritingActivitiesFiles/DCB_Site03-BT_Postcard.pdf"
import CHDPDF from "../../components/WritingActivities/WritingActivitiesFiles/DCB_Site06-CHD_Postcard.pdf"
import TCCCPDF from "../../components/WritingActivities/WritingActivitiesFiles/DCB_Site02-TCCC_Postcard.pdf"

function WritingActivities() {

  var _paq = window._paq = window._paq || [];
  /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
  _paq.push(['trackPageView']);
  _paq.push(['enableLinkTracking']);
  (function() {
    var u="https://matomo.aws.coh.arizona.edu/";
    _paq.push(['setTrackerUrl', u+'matomo.php']);
    _paq.push(['setSiteId', '64']);
    var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
    g.async=true; g.src=u+'matomo.js'; s.parentNode.insertBefore(g,s);
  })();

  return (
    <div style={{ paddingTop: "20vh", paddingLeft: "1vw", height: "100%" }}>
      <div
        style={{
          gap: "1vh",
          flexDirection: "column",
          display: "flex",
          marginBottom: "2vh",
        }}>
        <span className="title">Writing Activities</span>
        <div style={{ margin: "5vh" }}>
          The following writing prompts were created as a collaboration between
          the Discover Community in the Borderlands site partners and the
          University of Arizona Poetry Center. The prompts draw on historical
          and contemporary images as a way of encouraging visitors of all ages
          to reflect on their own experiences and how they, too, are part of
          Tucson's history.
          <br />
          <br />
          The Poetry Center is a leading literary institution and a living
          archive of poetry. As a premier example of a thriving public/private
          partnership, the Poetry Center connects the University of Arizona with
          the greater literary community in Tucson and beyond. We have amassed
          one of the finest and largest print/digital collections of
          contemporary poetry in America, with an active schedule of
          acquisitions. We’ve welcomed over one thousand poets to Tucson to
          read. Our education programs annually serve Arizona school children,
          college students, and adults with poetry experiences.
        </div>
      </div>

      <div className="activity">
        <p className="subtitle">Arizona State Museum</p>
        <img src={ASM} />
        <p className="caption">
          Coiled basketry jar, c. 2003, Annie Antone (Tohono O'odham), Arizona
          State Museum, 2017-160-1
        </p>
        <p>
          Indigenous basket makers use knowledge of the environment and weaving
          skills to make a basket. The designs often reflect community values
          and can tell stories. Going around this basket are social dancers
          dancing in the Sonoran Desert.
        </p>
        <p>
          What are the many things that a basket might hold? What types of items
          and knowledge go into a basket? What stories can it hold?&nbsp;
           <a href={ASMPDF} download="DCB_Site05-ASM_Postcard.pdf">
            Download printable version here
          </a>
        </p>
      </div>

      <div className="activity">
        <p className="subtitle">Borderlands Theater</p>
        <img src={Borderlands} />
        <p className="caption">
          From the private collection of Bob "Pepe" Galvez. Back row, L to R:
          Jose Galvez, Sylvia Galvez, Bob "Pepe" Galvez. Front row: Jesus (Jay)
          Lopez, Lupe Galvez, baby Rosemary. Early 1970s.
        </p>
        <p>
          "Memories are more than just recollections of past events. They
          influence the way we see and experience our present. We use memories
          to construct our origin story, our personal genesis of how we came to
          be and how our family began. To have an origin story is to be rooted
          and attached to the place of your creation." - Marc David Pinate
          Producing Director and Ensemble Member of Borderlands Theater&nbsp;
          <a href={BorderlandsPDF} download="DCB_Site03-BT_Postcard.pdf">
            Download printable version here
          </a>
        </p>
        <p></p>
      </div>

      <div className="activity">
        <p className="subtitle">Chinese History Downtown</p>
        <img src={CHD} />
        <p className="caption">
          Richelieu Cafe 1930s, Arizona Historical Society, Buehman Collection
          #B29261
        </p>
        <p>
          The Chinese-owned Richelieu Cafe on Congress Street served as a
          gathering point for community members from Mexican, Anglo, Chinese,
          and O'odham communities among others.
        </p>
        <p>
          What is a gathering place that makes you feel welcome, and where you
          connect with people from different walks of life?
          <br />
          Why is this place meaningful to you? Describe what it's like to be
          there using your senses and descriptive imagery.&nbsp;
          <a href={CHDPDF} download="DCB_Site06-CHD_Postcard.pdf">
            Download printable version here
          </a>
        </p>
      </div>

      <div className="activity">
        <p className="subtitle">Dunbar Pavilion</p>
        <img src={Dunbar} />
        <p className="caption">
          Dunbar School Cafeteria, in basement, prior to building of 1948
          cafeteria. Date unknown but likely around 1946.
        </p>
        <p>
          "Dunbar had no cafeteria until 1948. We either used a classroom to eat
          our lunches, which we brought from home, or ate them on the
          playground. The mother of one of Dunbar's students, Mrs. Warrior,
          cooked a special lunch for the students on Thursdays. She would cook
          chili beans, cornbread and a salad. Almost everyone loved her chili
          beans and almost everyone reminded their parents not to make their
          lunch and would pay their five-to-ten cents for those delicious
          beans." - Barbara Lewis, Dunbar Pavilion Board Member
        </p>
        <p>
          What is a food that someone makes — or used to make — for you that you
          love? write a little bit about it.&nbsp;
          <a href={DunbarPDF} download="DCB_Site01-Dunbar_Postcard.pdf">
            Download printable version here
          </a>
        </p>
      </div>

      <div className="activity">
        <p className="subtitle">Mission Garden</p>
        <img src={MissionGarden} />
        <p className="caption"></p>
        <p>
          "Challenges of climate change, population growth and water scarcity
          lead farmers to ask new questions about how to grow food in a changing
          landscape. Honoring Tucson's diverse community and unique history,
          "Tomorrow's Garden" will punctuate Mission Garden's Timeline of
          agricultural plots with a demonstration of sustainable and innovative
          agricultural practices."
        </p>
        <br />
        <p>
          Help imagine Tomorrow's Garden at Mission Garden. How will people grow
          food in Tucson in the future?&nbsp;
          <a href={MissionGardenPDF} download="DCB_Site04-MG_Postcard.pdf">
            Download printable version here
          </a>
        </p>
      </div>

      <div className="activity">
        <p className="subtitle">Tucson Chinese Cultural Center</p>
        <img src={TCCC} />
        <p className="caption"></p>
        <p>
          "Kongzhu, or Chinese yo yo, is an ancient performance discipline in
          Chinese culture. It is well-documented from the 14th Century onward in
          the courts of the Chinese Emperors and in other locations, including
          festivals roving from village to village. Traditionally it was called
          the Empty Bell because of the sounds made during perofrmace; the disc
          was formed in such a way that it created a whistling sound. Now it is
          very popular among young Chinese Americans wishing to stay in touch
          with their traditional culture." - Robin Blackwood, TCCC History
          Committee Member
        </p>
        <p>
          What's a game that you're passionate about? Do you think that game is
          a form of art? why or why not?&nbsp;
          <a href={TCCCPDF} download="DCB_Site02-TCCC_Postcard.pdf">
            Download printable version here
          </a>
        </p>
      </div>
    </div>
  );
}

export default WritingActivities;
